import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PageTitle from "../../components/PageTitle";
import Api from "../../constant/Api";
import { getRequest } from "../../constant/ApiService";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../context/MainContext";
import { Pagination } from "@material-ui/lab";

const TransactionsCancel = () => {
  const [items, setItems] = useState([]);
  const [count, setCount] = useState([]);
  const history = useHistory();

  let {
    page,
    setPage,
  } = useContext(MainContext);

  useEffect(() => {
    GetCanceledCargoes()
  }, []);

  const GetCanceledCargoes = () => {
    getRequest(`${Api.GetCanceledCargoes}?PageNumber=${page}&PageSize=${20}`).then(res => {
      setItems(res && res.data.lists);
      setCount(res && res.data.count);
    });
  }

  const handleChange = (event, value) => {
    setPage(value);
    GetCanceledCargoes();
  };

  return (
    <Grid>
      <PageTitle title="بارهای رد شده" />
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 150 }}>ردیف</TableCell>
              <TableCell style={{ minWidth: 150 }}>شناسه</TableCell>
              <TableCell style={{ minWidth: 150 }}>مشخصات مشتری</TableCell>
              <TableCell style={{ minWidth: 200 }}>
                مشخصات تحویل گیرنده
              </TableCell>
              <TableCell>بار</TableCell>
              <TableCell style={{ minWidth: 150 }}>نوع کامیون</TableCell>
              <TableCell style={{ minWidth: 200 }}>
                تاریخ و ساعت بارگیری
              </TableCell>
              <TableCell style={{ minWidth: 200 }}>
                تاریخ و ساعت ثبت سفارش
              </TableCell>
              <TableCell>بار نامه</TableCell>
              <TableCell>علت کنسلی</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.length > 0 &&
              items.map((item, index) => (
                <TableRow
                  key={index}
                  onClick={() => {
                    history.push({
                      pathname: `/app/transactionsDetail/${item.id}`,
                      state: { detailData: "cancelData" },
                    });
                  }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>
                    {item.cargoOwnerName}
                    <br />
                    {item.cargoOwnerPhoneNumber}
                  </TableCell>
                  <TableCell>
                    {item.transfereeName}
                    <br />
                    {item.transfereePhoneNumber}
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.truckTypeName}</TableCell>
                  <TableCell>{item.persianLoadingDateTime}</TableCell>
                  <TableCell>{item.persianCreated}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{item.description}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
            count={items && items.length > 0 ? Math.ceil(count / 20) : 0}
            page={page}
            onChange={handleChange}
            color="primary"
            variant="outlined"
            shape="rounded"
            size="large"
            style={{ marginTop: "2vh" }}
       />
    </Grid>
  );
};

export default TransactionsCancel;
