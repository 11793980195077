export const BASEURL2 = "https://apis.barmap.ir";

export default {
  /////////////////LOGIN/////////////////////////////////////////////////////////////////////////
  LoginByEmailUserName: BASEURL2 + "/api/Admin/Account/LoginByEmailUserName",
  /////////////////TRANSCTIONS//////////////////////////////////////////////////////////////////
  GetTransactionsWaiting:
  BASEURL2 + "/api/Admin/Cargo/GetWaitingConfirmationCargoes",
  GetInProgressCargoes: BASEURL2 + "/api/Admin/Cargo/GetInProgressCargoes",
  GetDoneCargoes: BASEURL2 + "/api/Admin/Cargo/GetDoneCargoes",
  GetCanceledCargoes: BASEURL2 + "/api/Admin/Cargo/GetCanceledCargoes",
  GetPackageTypes: BASEURL2 + "/api/Admin/Cargo/GetPackageTypes",
  GetWeights: BASEURL2 + "/api/Admin/Cargo/GetWeights",
  GetCargoLoadingTypes: BASEURL2 + "/api/Admin/Cargo/GetCargoLoadingTypes",
  GetCargoDetail: BASEURL2 + "/api/Admin/Cargo/GetCargoDetail",
  GetCarrierTypes: BASEURL2 + "/api/Admin/Cargo/GetCarrierTypes",
  GetConfirmCargo: BASEURL2 + "/api/Admin/Cargo/ConfirmCargo",
  UpdateCargo: BASEURL2 + "/api/Admin/Cargo/UpdateCargo",
  CreateCargoAddress: BASEURL2 + "/api/Admin/Cargo/CreateCargoAddress",
  DeleteCargo: BASEURL2 + "/api/Admin/Cargo/Delete",
  DeleteCargoAddress: BASEURL2 + "/api/Admin/Cargo/DeleteCargoAddress",
  GetDriversOfCargo: BASEURL2 + "/api/Admin/Cargo/GetDriversOfCargo",
  DriverApprovedToCargo:BASEURL2 + "​/api​/Admin​/Cargo​/DriverApprovedToCargoCommand",
  ChangeCancelCargoToAnotherState:BASEURL2 + "/api/Admin/Cargo/ChangeCancelCargoToAnotherState",
  GetTruckTypes: BASEURL2 + "/api/Admin/Cargo/GetTruckTypes",
  GetCargoLoadingTypes: BASEURL2 + "/api/Admin/Cargo/GetCargoLoadingTypes",
  CreateCargo: BASEURL2 + "/api/Admin/Cargo/Create",
  SetWayBill: BASEURL2 + "/api/App/Cargo/SetWayBill",
  ChangeStateCargo: BASEURL2 + "/api/Admin/Cargo/GetCargoStates",
  GetDriversNotBookCargo: BASEURL2 + "/api/Admin/Cargo/GetDriversNotBookCargo",
  DriverCargoReserveByAdmin: BASEURL2 + "/api/Admin/Cargo/DriverCargoReserveByAdmin",
  NewSocialMediaOrder: BASEURL2+ "/api/Admin/Cargo/CreateSocialMediaCargo",
  CreateNewCargo: BASEURL2 + "/api/Admin/Cargo/CreateNewCargo",
  GetDashboardReport: BASEURL2 + "/api/Admin/Report/GetDashboardReport",





  /////////////CUSTOMER//////////////////////////////////////////////////////////////////////////
  GetCargoOwnersNaturalList:
    BASEURL2 + "/api/Admin/CargoOwner/GetCargoOwnersNatural_List",
  UpdateNaturalCustomer: BASEURL2 + "/api/Admin/CargoOwner/UpdateNatural",
  GetCargoOwnersLegalList:
    BASEURL2 + "/api/Admin/CargoOwner/GetCargoOwnersLegal_List",
  UpdateLegalCustomer: BASEURL2 + "/api/Admin/CargoOwner/UpdateLegal",
  GetCargoOwnersOperatorList:
    BASEURL2 + "/api/Admin/CargoOwner/GetCargoOwnersOperator_List",
  UpdateOperatorCustomer: BASEURL2 + "/api/Admin/CargoOwner/UpdateOperator",
  CreateNaturalCustomer: BASEURL2 + "/api/Admin/CargoOwner/CreateNatural",
  CreateLegalCustomer: BASEURL2 + "/api/Admin/CargoOwner/CreateLegal",
  CreateOperatorCustomer: BASEURL2 + "/api/Admin/CargoOwner/CreateOperator",
  ////////////////DRIVERS//////////////////////////////////////////////////////////////////////////
  GetDriversInfoList: BASEURL2 + "/api/Admin/Driver/GetDriversInfo_List",
  CreateDriver: BASEURL2 + "/api/Admin/Driver/CreateDriver",
  CreateDriverByNavy: BASEURL2 + "/api/Admin/Driver/CreateDriverByNavy",
  CreateDriverAndNavy: BASEURL2 + "/api/Admin/Driver/CreateDriverAndNavy",
  ////////////////TRANSACTIONCompany///////////////////////////////////////////////////////////////
  GetTransportationCompanyList:
    BASEURL2 + "/api/Admin/TransportationCompany/GetTransportationCompany_List",
  UpdateTransportationCompany:
    BASEURL2 + "/api/Admin/TransportationCompany/UpdateTransportationCompany",
  CreateTransportationCompany:
    BASEURL2 + "/api/Admin/TransportationCompany/CreateTransportationCompany",
  //////////ABOUTUS////////////////////////////////////////////////////////////////////////////////
  GetAboutUs: BASEURL2 + "/api/Common/About/GetAboutUs",
  CreateAboutUs: BASEURL2 + "/api/Common/About/CreateAboutUs",
  //////////RULE//////////////////////////////////////////////////////////////////////////////////
  GetRule: BASEURL2 + "/api/Common/Rule/GetRule",
  CreateRule: BASEURL2 + "/api/Common/Rule/CreateRule",
  //////////COMMENT//////////////////////////////////////////////////////////////////////////////////
  GetComment: BASEURL2 + "/api/Common/Comment/GetComments_List",
  CreateComment: BASEURL2 + "/api/Common/Comment/CreateComment",
  //////////CITY/////////////////////////////////////////////////////////////////////////////////////
  GetCity: BASEURL2 + "/api/Common/City/GetCities",
    //////////CRMUSER/////////////////////////////////////////////////////////////////////////////////////
    RegisterCrmUser: BASEURL2 + "/api/Admin/Account/RegisterCrmUser",
    GetCrmUser: BASEURL2 + "/api/Admin/Account/GetCrmUser",
    GetRoles: BASEURL2 + "/api/Admin/Roles/GetAllRoles",
};
