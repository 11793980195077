import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//login methods
export const loginReqest = async (data, body) => {
  const response = await axios.post(data, body).catch(error => {
    let message = error?.response;
    if (!message) {
      toast.error("خطای در سرور اتفاق افتاده است");
    }
    if (message?.status == 500) {
      toast.error("خطای در سرور اتفاق افتاده است");
    }
    if (message?.data.Status == 400 || message?.data.Status == 403) {
      message.data.errors.map(err => toast.error(err.error));
    }
  });
  if (response && response.data.succeeded) {
    return response;
  }
};

export const getRequestAutoComplete= async data => {
  let config = { headers: { 'x-api-key': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjQwMzJhNDk0NTc4NDYzNjljMDU3NjUzNmU5NGI3NzQ0MTc1Yjc1MWI0NzAxOTMyYTU3MWU5ZjIwOGJmY2FiYTJhM2M3Y2JiZTFmZWI0YmY5In0.eyJhdWQiOiIxNjM2MiIsImp0aSI6IjQwMzJhNDk0NTc4NDYzNjljMDU3NjUzNmU5NGI3NzQ0MTc1Yjc1MWI0NzAxOTMyYTU3MWU5ZjIwOGJmY2FiYTJhM2M3Y2JiZTFmZWI0YmY5IiwiaWF0IjoxNjM5NjQ0NzgwLCJuYmYiOjE2Mzk2NDQ3ODAsImV4cCI6MTY0MjE1MDM4MCwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.XGTanHNh9Wxh5n9GyE_CAG7US0D8r21BWSrY9t295Wd2pxbbXqtvJcChPh9Rd5O-1XgYLBMyb56vmPa-yUL9F4OL-sHc6iKe0ZNr2nAoPquZ2blV7kaDg2E2qeDLmyAePjvrgwRtEAcqDZG8O2VjjtFVSR0srYAkV0UHwKDExmYQ-kIoUqgPzU7OkaO1tr7G7eEIjMiG9aGsPM4Wuq7d_NI4qaYJ6OyIDPDyjv1OfrcEi87Jfk2ewLoC6Rg1dSRjw-Ri2hbIetPiX9B_yXG87U-dhyojpGiSaqCijCpeE0ELEWzEbh0BhppmK3CgeHf73AlKl01fPV2qpBS64ljgMQ' } };
  let response = await axios.get(data, config).catch(error => {
    let message = error.response;
    if (message) {
      if (!message) {
        toast.error("خطای در سرور اتفاق افتاده است");
      }
      if (message && message.status == 500) {
        toast.error("خطای در سرور اتفاق افتاده است");
      }
      if (
        (message && message.data !== undefined && message.data.Status == 400) ||
        message.data.Status == 403
      ) {
        message.data.errors.map(err => toast.error(err.error));
      }
    } else {
      toast.error("خطای ارتباط با شبکه ");
    }
  });

  console.log(response,"cityOption3")

  if (response && response.data.succeeded) return response.data;
};
//Get methods
export const getRequest = async data => {
  let token = sessionStorage.getItem("token");
  let config = { headers: { Authorization: `Bearer ${token}` } };
  let response = await axios.get(data, config).catch(error => {
    let message = error?.response;
    if (message) {
      if (!message) {
        toast.error("خطای در سرور اتفاق افتاده است");
      }
      if (message && message?.status == 500) {
        toast.error("خطای در سرور اتفاق افتاده است");
      }
      if (
        (message && message?.data !== undefined && message?.data.Status == 400) ||
        message.data.Status == 403
      ) {
        message.data.errors.map(err => toast.error(err.error));
      }
    } else {
      toast.error("خطای ارتباط با شبکه ");
    }
  });
  if (response && response.data.succeeded) return response.data;
};

//Post methods
export const postRequest = async (data, body) => {
  let token = sessionStorage.getItem("token");
  let config = { headers: { Authorization: `Bearer ${token}` } };

  let response = await axios.post(data, body, config).catch(error => {
    let message = error.response;
    if (message) {
      if (!message) {
        toast.error("خطای در سرور اتفاق افتاده است");
      }
      if (message.status == 500) {
        toast.error("خطای در سرور اتفاق افتاده است");
      }
      if (message.data.Status == 400 || message.data.Status == 403) {
        message.data.errors.map(err => toast.error(err.error));
      }
    } else {
      toast.error("خطای ارتباط با شبکه ");
    }
  });
  if (response && response.data.succeeded) return response.data;
};

//Put methods
export const putRequest = async (data, body) => {
  let token = sessionStorage.getItem("token");
  let config = { headers: { Authorization: `Bearer ${token}` } };

  const response = await axios.put(data, body, config).catch(error => {
    let message = error.response;
    if (message) {
      if (!message) {
        toast.error("خطای در سرور اتفاق افتاده است");
      }
      if (message.status == 500) {
        toast.error("خطای در سرور اتفاق افتاده است");
      }
      if (message.data.Status == 400 || message.data.Status == 403) {
        message.data.errors.map(err => toast.error(err.error));
      }
    } else {
      toast.error("خطای ارتباط با شبکه ");
    }
  });
  if (response && response.data.succeeded) return response.data;
};

//Delete methods
export const deleteRequest = async data => {
  let token = sessionStorage.getItem("token");
  let config = { headers: { Authorization: `Bearer ${token}` } };

  const response = await axios.delete(data, config).catch(error => {
    let message = error.response;
    if (message) {
      if (!message) {
        toast.error("خطای در سرور اتفاق افتاده است");
      }
      if (message.status == 500) {
        toast.error("خطای در سرور اتفاق افتاده است");
      }
      if (message.data.Status == 400 || message.data.Status == 403) {
        message.data.errors.map(err => toast.error(err.error));
      }
    } else {
      toast.error("خطای ارتباط با شبکه ");
    }
  });
  if (response && response.data && response.data.succeeded) {
    return response

    //return toast.success(response.data.message);
  }
  return response
};
