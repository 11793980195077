import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Api from "../../../constant/Api";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { MainContext } from "../../../context/MainContext";
import { getRequest } from "../../../constant/ApiService";
import { useHistory } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import "react-toastify/dist/ReactToastify.css";

const FinishedTransaction = () => {
  const [items, setItems] = useState([]);
  const [count, setCount] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  let {
    handleChangeRowsPerPage,
    rowsPerPage,
    page,
    setPage 
  } = useContext(MainContext);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getDoneCargoes = () => {
    getRequest(`${Api.GetDoneCargoes}?PageNumber=${page}&PageSize=${20}`).then(res => {
      setItems(res && res.data.lists);
      setCount(res && res.data.count)

    });
  }

  useEffect(() => {
    getDoneCargoes()
  }, []);


  const handleChange = (event, value) => {
    setPage(value);
    getDoneCargoes();
  };


  return (
    <Grid>
      <PageTitle title="بارهای انجام شده" />
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 150 }}>ردیف</TableCell>
              <TableCell style={{ minWidth: 150 }}>شناسه</TableCell>
              <TableCell style={{ minWidth: 150 }}>مشخصات مشتری</TableCell>
              <TableCell style={{ minWidth: 200 }}>
                مشخصات تحویل گیرنده
              </TableCell>
              <TableCell>بار</TableCell>
              <TableCell style={{ minWidth: 150 }}>نوع کامیون</TableCell>
              <TableCell style={{ minWidth: 200 }}>
                تاریخ و ساعت بارگیری
              </TableCell>
              <TableCell style={{ minWidth: 200 }}>
                تاریخ و ساعت ثبت سفارش
              </TableCell>
              <TableCell>امتیاز</TableCell>
              <TableCell>اقدام</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.length > 0 &&
              items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    onClick={() => {
                      history.push({
                        pathname: `/app/transactionsDetail/${item.id}`,
                        state: { detailData: "finishedData" },
                      });
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      history.push({
                        pathname: `/app/transactionsDetail/${item.id}`,
                        state: { detailData: "finishedData" },
                      });
                    }}
                  >
                    {item.id}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      history.push({
                        pathname: `/app/transactionsDetail/${item.id}`,
                        state: { detailData: "finishedData" },
                      });
                    }}
                  >
                    {item.cargoOwnerName}
                    <br />
                    {item.cargoOwnerPhoneNumber}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      history.push({
                        pathname: `/app/transactionsDetail/${item.id}`,
                        state: { detailData: "finishedData" },
                      });
                    }}
                  >
                    {item.transfereeName}
                    <br />
                    {item.transfereePhoneNumber}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      history.push({
                        pathname: `/app/transactionsDetail/${item.id}`,
                        state: { detailData: "finishedData" },
                      });
                    }}
                  >
                    {item.title}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      history.push({
                        pathname: `/app/transactionsDetail/${item.id}`,
                        state: { detailData: "finishedData" },
                      });
                    }}
                  >
                    {item.truckTypeName}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      history.push({
                        pathname: `/app/transactionsDetail/${item.id}`,
                        state: { detailData: "finishedData" },
                      });
                    }}
                  >
                    {item.persianLoadingDateTime}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      history.push({
                        pathname: `/app/transactionsDetail/${item.id}`,
                        state: { detailData: "finishedData" },
                      });
                    }}
                  >
                    {item.persianCreated}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      history.push({
                        pathname: `/app/transactionsDetail/${item.id}`,
                        state: { detailData: "finishedData" },
                      });
                    }}
                  >
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          width: "20ch",
                        },
                      }}
                    >
                      <MenuItem selected="Pyxis" onClick={handleClose}>
                        <Button
                          style={{ width: "100%" }}
                          variant="contained"
                          color="primary"
                        >
                          پیگیری سفارش
                        </Button>
                      </MenuItem>
                      <MenuItem selected="Pyxis" onClick={handleClose}>
                        <Button
                          style={{ width: "100%" }}
                          variant="contained"
                          color="info"
                        >
                          نقشه
                        </Button>
                      </MenuItem>

                      <MenuItem selected="Pyxis" onClick={handleClose}>
                        <Button
                          style={{ width: "100%" }}
                          variant="contained"
                          color="secondary"
                        >
                          پرداخت
                        </Button>
                      </MenuItem>
                      <MenuItem selected="Pyxis" onClick={handleClose}>
                        <Button
                          style={{ width: "100%" }}
                          variant="contained"
                          color="secondary"
                        >
                          تغییر وضعیت
                        </Button>
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
            count={items && items.length > 0 ? Math.ceil(count / 20) : 0}
            page={page}
            onChange={handleChange}
            color="primary"
            variant="outlined"
            shape="rounded"
            size="large"
            style={{ marginTop: "2vh" }}
       />
     
    </Grid>
  );
};

export default FinishedTransaction;
